<template>
    <div style="min-width: 1360px;">
        <Header/>
        <headerBanner :content="bannerContent" :price="bannerPrice" @btnClick="bannerClick" title="千企云-低代码开发平台" imgStyle="height:0%;height:0" contentStyle="width:100%" :bodyStyle="bodyStyle"/>

        <div class="card">
            <div class="card-container">
                <div class="head-card-title">提高开发效率 为企业增产增效</div>
                <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;">
                <div v-for="(item,i) in dataList" :key="i" class="cell-card">
                    <img class="cell-card-img" :src="item.img">
                    <div class="cell-card-title">{{ item.title }}</div>
                    <div class="cell-card-content">{{ item.content }}</div>
                </div>
                </div>
            </div>
        </div>

        <div class="card" style="background-color: #F7F8FE;">
            <div class="card-container">
                <div class="head-card-title">系统优势，助力提升开发效率</div>
                <div class="center-content">
                <div class="left">
                    <div>
                        <div class="left-title">1.低门槛</div>
                        <div class="left-tips">无需专业编程技能，通过拖拽和配置即可完成应用开发，降低开发门槛</div>
                    </div>
                    <div>
                        <div class="left-title">2.高效率</div>
                        <div class="left-tips">内置丰富的功能模块和行业解决方案，能快速响应业务需求，提升开发效率</div>
                    </div>
                    <div>
                        <div class="left-title">3.灵活性</div>
                        <div class="left-tips">支持自定义流程、数据模型和界面布局，满足企业个性化需求</div>
                    </div>
                    <div>
                        <div class="left-title">4.易维护</div>
                        <div class="left-tips">提供一键部署和升级功能，简化应用的维护工作</div>
                    </div>
                    <div>
                        <div class="left-title">5.安全可靠</div>
                        <div class="left-tips">具备完善的权限管理和数据安全保障机制，确保企业数据的安全性</div>
                    </div>
                </div>
                <div>
                    <img :src="require('@/assets/img/home/LeiurYjJcv.png')" alt=""
                        style="width: 650px;height: 397px;object-fit: cover;">
                </div>
                </div>
            </div>
        </div>

        <applyTrial ref="dialog" :servicePlatform="3"/>
        <new-cloude-footer/>
    </div>
</template>

<script>
import Header from "@/views/home/newCloudeHeader.vue";
import headerBanner from '@/components/assembly/headerBanner';
import applyTrial from "@/components/assembly/applyTrial";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";
export default {
    components: {
        Header,
        headerBanner,
        applyTrial,
        newCloudeFooter
    },
    data() {
        return {
            bannerPrice:'市场价格：730000元',
            bannerContent: '千企云-低代码开发平台适用于各类企业，特别是对于有快速构建应用、提升业务效率需求的企业，如金融、教育、医疗、零售等行业。',
            bodyStyle: `background-image:url(${require('@/assets/img/home/WDKI0LH_y.png')});background-size: contain;background-repeat: no-repeat;background-position: right 25px;`,
            dataList: [
                {
                    img: require('@/assets/img/home/finance_card_1.png'),
                    title: '可视化设计',
                    content: '提供直观的拖拽界面，用户可以通过拖拽组件、设置属性等方式快速构建应用界面'
                },
                {
                    img: require('@/assets/img/home/finance_card_2.png'),
                    title: '数据管理',
                    content: '支持多种数据库类型，提供数据模型设计、数据导入导出、数据关联等功能，方便用户管理业务数据'
                },
                {
                    img: require('@/assets/img/home/finance_card_3.png'),
                    title: '业务流程设计',
                    content: '提供流程设计工具，支持自定义流程节点和流转规则，满足企业复杂的业务流程需求'
                },
                {
                    img: require('@/assets/img/home/finance_card_4.png'),
                    title: '移动适配',
                    content: '支持一键生成移动端应用，满足企业在移动端的业务需求'
                },
                {
                    img: require('@/assets/img/home/finance_card_5.png'),
                    title: '权限管理',
                    content: '提供细粒度的权限控制，确保数据安全和应用的合规性'
                },
                {
                    img: require('@/assets/img/home/finance_card_6.png'),
                    title: '集成与扩展',
                    content: '支持与其他系统进行集成，提供API接口和插件机制，方便用户扩展平台功能'
                },
                {
                    img: require('@/assets/img/home/finance_card_7.png'),
                    title: '报表与分析',
                    content: '提供丰富的报表组件和数据分析工具，帮助企业快速构建数据分析报表，辅助决策'
                },
                {
                }
            ],
        }
    },
    methods: {
        bannerClick() {
            this.$refs.dialog.show = true;
        }
    }
}
</script>

<style lang="scss" scoped>

.card {
  padding: 60px 0;
  &-container {
    width: 1200px;
    margin: auto;
  }
}

.head-card-title {
  font-weight: bold;
  font-size: 30px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.cell-card {
  width: 290px;
  height: 335px;
  margin-top: 10px;
  border: 1px solid #EAEDFF;
  padding: 20px;
  box-sizing: border-box;

  &-title {
    font-size: 16px;
    line-height: normal;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 16px;
  }

  &-content {
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    text-align: justify;
  }

  &-img {
    width: 100%;
    height: 130px;
  }

  &:nth-child(8) {
    background-color: transparent;
    border: none;
    .cell-card-img {
        width: 0px;
        height: 0px;
    }
  }
}

.center-content {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .left-title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
    .left-tips {
      font-size: 16px;
      color: #666;
      margin-top: 14px;
    }
  }
}

</style>